import React, { Fragment } from 'react'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { Container, Row } from 'reactstrap'
import Img from 'gatsby-image'

import * as Routes from 'routes'
import Layout from 'layouts/static'

import Main from 'components/Main'
import * as CompanyMark from 'components/CompanyMark'
import ButtonLink from 'components/Button/Link'
import Heading from 'components/Heading'
import H2 from 'components/H2'
import H3 from 'components/H3'
import H4 from 'components/H4'
import * as Card from 'components/Card'
import CardLink from 'components/Card/Link'
import Services from 'components/Services'
import Portfolio, {
  Mockup as PortfolioMockup
} from 'components/PortfolioWidget'
import Steps from 'components/Steps'
import ContactWidget from 'components/ContactWidget'

const MiscServicesPage = ({ data }) => (
  <Layout header={header}>
    <Helmet title="Inne usługi">
      <meta
        name="description"
        content="Nigdy nie odmawiamy naszym klientom. Jeśli masz dla nas realizację, która nie znalazła miejsca na naszej stronie internetowej, nie oznacza to, że nie potrafimy jej zrobić. Po prostu skontaktuj się z nami, a odpowiemy szybciej, niż myślisz."
      />
    </Helmet>

    <Main>
      <Heading noMargin id="wsparcie">
        <H4>
          <CompanyMark.Left /> Z Karer Media możesz więcej <CompanyMark.Right />
        </H4>
        <H2 className="text-xl-left">Wsparcie dla Twojego biznesu</H2>
      </Heading>

      <Services className="pt-1">
        <Container>
          <Row>
            <div className="col-12 col-md-8 mx-auto mb-5 text-center">
              Naszą agencją tworzą osoby biegłe w różnych dziedzinach
              programowania - dzięki tej mieszance talentów możesz mieć partnera
              od wszystkiego, ale zdecydowanie nie do niczego. Przekonaj się o
              tym!
            </div>
          </Row>
          <Row>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Intuicyjny CRM</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Wygodny, przejrzysty i funkcjonalny CRM to marzenie wielu
                    firm. Szkoda Ci czasu? Masz swoje wymagania? Potrzebujesz
                    programu dedykowanego Twoim potrzebom. Właśnie takie CRMy
                    robimy!
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4 mb-4 mb-lg-0">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Boty / scrapping</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Automatyzacja pracy to słowo, które wywołuje ciarki na
                    plecach wielu firm. Tworzymy programy, które potrafią
                    wyręczyć człowieka. I zaoszczędzić Twój czas
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
            <div className="col-12 col-md px-4">
              <Link to={Routes.CONTACT}>
                <Card.Wrapper noIcon>
                  <Card.Icon className="mx-auto">
                    <H3>Game Development</H3>
                  </Card.Icon>
                  <div className="mt-4">
                    Gry na platformy mobilne i webowe jednocześnie? Stworzymy
                    dla Twojej firmy grę, która wciągnie każdego gracza. Wiemy o
                    nich wszystko!
                  </div>
                  <CardLink>
                    Dowiedz się więcej <span>&gt;</span>
                  </CardLink>
                </Card.Wrapper>
              </Link>
            </div>
          </Row>
        </Container>
      </Services>

      <Portfolio>
        <Container>
          <Row>
            <div className="col-12 col-xl align-self-end mb-5 mb-xl-0">
              <PortfolioMockup fluid>
                <Img
                  fluid={data.portfolioMockup.childImageSharp.fluid}
                  alt="Realizacje"
                />
              </PortfolioMockup>
            </div>
            <div className="col-12 col-xl-6 offset-xl-1">
              <Heading left noBackground>
                <H4 className="text-center text-xl-left">
                  <CompanyMark.Left /> Realizacje <CompanyMark.Right />
                </H4>
                <H2 className="text-center text-xl-left">
                  Dlaczego Karer Media
                </H2>
                <div className="mt-3 text-center text-xl-left">
                  <strong>Wiemy, czego potrzebujesz.</strong>
                  <div className="my-4">
                    Nasz zespół na bieżąco śledzi nowinki branżowe i doskonale
                    zna potrzeby przedsiębiorców. Dzięki temu oszczędzamy Twój
                    czas i Twoje pieniądze.
                  </div>
                  <ButtonLink
                    to={Routes.PORTFOLIO}
                    variant="primary"
                    className="mb-5"
                  >
                    Zobacz portfolio
                  </ButtonLink>
                </div>
              </Heading>
            </div>
          </Row>
        </Container>
      </Portfolio>

      <Steps secondary>
        <Container>
          <Row>
            <Heading noBackground light>
              <H4>
                <CompanyMark.Left /> Współpraca z nami <CompanyMark.Right />
              </H4>
              <H2>Potrzebujesz czegoś jeszcze?</H2>
            </Heading>
          </Row>
          <Row>
            <div className="col-12 col-md-11 mx-auto mt-5 pt-3">
              W Karer Media nigdy nie odmawiamy naszym klientom. Dlatego jeśli
              masz dla nas realizację, która nie znalazła miejsca na naszej
              stronie internetowej, nie oznacza to, że nie potrafimy jej zrobić.
              Po prostu skontaktuj się z nami, a odpowiemy szybciej, niż
              myślisz.
            </div>
          </Row>
        </Container>
      </Steps>

      <ContactWidget
        title="Skontaktuj się z nami!"
        subtitle="Porozmawiaj z nami"
      />
    </Main>
  </Layout>
)

export const query = graphql`
  query {
    portfolioMockup: file(
      relativePath: { eq: "page_misc_services/portfolio_mockup.png" }
    ) {
      childImageSharp {
        fluid(maxWidth: 572, maxHeight: 378) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`

const header = {
  title: (
    <Fragment>
      Aplikacje, strony, sklepy - dla nas to nie wszystko<span>.</span>
    </Fragment>
  ),
  subtitle: <Fragment>Inne usługi</Fragment>,
  content: (
    <div>
      <div className="my-5">Co jeszcze możemy dla Ciebie zrobić?</div>
      <ButtonLink
        to="#"
        variant="primary"
        onClick={e => {
          e.preventDefault()

          document
            .getElementById('wsparcie')
            .scrollIntoView({ behavior: 'smooth' })
        }}
      >
        Dowiedz się więcej
      </ButtonLink>
    </div>
  ),
  image: 'miscServices'
}

export default MiscServicesPage
