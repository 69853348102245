import styled from 'styled-components'
import { Container } from 'reactstrap'

import media from 'styles'

import Background from 'images/universal_particles.png'

export default styled(Container).attrs({ fluid: true })`
  position: relative;
  padding: 0;
  margin-top: 50px;

  strong {
    font-size: 30px;
    font-weight: 400;
    color: #128e7d;
  }

  ${ media.greaterThan('lg')`
    margin-top: 120px;
    margin-bottom: -40px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      height:120%;
      background: url(${ Background }) no-repeat left -40%;
      z-index: -2;
    }
  ` }
`

export const Mockup = styled.div`
  ${ media.lessThan('xl')`
    width: 40%;
    margin: 0 auto;
  ` }

  ${ media.greaterThan('xl')`
    width: ${ props => (props.fluid ? 100 : 70) }%;
    position: absolute;
    bottom: 0;
    right: 0;
  ` }
`
